.footer-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: space-between;
}

.footer-tdp-container {
    flex: 2;
    display: flex;
    flex-direction: column;
    margin: 50px 0 50px 30px;
    justify-content: center;
}

.footer-link-container {
    flex: 10;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.footer-link-subcontainer-left{
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
    height: 120px;
    padding-bottom: 30px;
}

.footer-link-subcontainer-right{
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    height: 120px;
    padding-bottom: 30px;
}

.footer-link-left {
    font-family: "Playfair Display";
    font-weight: 500;
    font-size: 1rem;
    text-align: right;
}

.footer-link-right {
    font-family: "Playfair Display";
    font-weight: 500;
    font-size: 1rem;
    text-align: left;
}

.footer-tdp-name {
    font-family: "cormorant-garamond";
    font-weight: 700;
    font-size: 2rem;
    font-style: italic;
    word-wrap: break-word;
}

.footer-copyright {
    position: absolute;
    bottom: 8px;
    right: 16px;
    font-family: "Playfair Display";
    font-weight: 500;
    word-wrap: break-word;
    font-size: 0.9rem;
}

.footer-tdp-logo {
    max-width: 60px;
}

@media (max-width: 768px) {
    .footer-tdp-logo {
        max-width: 40px;
    }
    .footer-tdp-name {
        font-size: 1rem;
    }
    .footer-link-left {
        font-size: 0.7rem;
    }
    .footer-link-right {
        font-size: 0.7rem;
    }
    .footer-copyright {
        font-size: 0.6rem;
    }

}