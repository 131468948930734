.main-container {
    width: auto;
    display: flex;
    justify-content: center;
    align-self: center;
    margin: 0px 30px;
}

.search-bar-container {
    width: 100%;
    justify-content: flex-start; 
    align-items: center;
    display: flex;
    border: 0.40px black solid;
    border-radius: 100px;
    padding: 15px 15px 15px 50px;
  }
  
  .search-input {
    width: 100%;
    border: 0;
    outline: none;
    padding: 0 0 0 30px;
    color: #010105;
    font-size: 1.5rem;
    font-family: "cormorant-garamond";
    font-style: italic;
    font-weight: 400;
    word-wrap: break-word;
  }

  @media (max-width: 768px) {
    .search-bar-container {
      padding: 10px 5px 10px 25px;
    }

    .main-container {
      margin: 0px 10px;
    }

    .search-input {
      padding: 0 0 0 10px;
      font-size: 1rem;
    }
  }