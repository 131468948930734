.discoverAndCompare-container {
    position: relative;
    display: flex;
    justify-content: center;
    padding-top: 30px;
    margin-top: 25px;
}

.discoverAndCompare-image {
    max-height: 600px;
    width: 100%;
}

.discoverAndCompare-sub-container {
    position: absolute;
    display: flex;
    top: 0;
}

.discoverAndCompare-sub-sub-container{
    max-width: 500px;
    max-height: 500px;
    background-color: rgba(1, 1, 5, 0.75);
    margin-left: 15px;
    margin-right: 15px;
}

.discoverAndCompare-sub-sub-sub-container {
    border: 1px white solid;
    margin: 20px;
    width: 8rem;
    height: 8rem;
    padding: 6rem;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}
 .discoverAndCompare-text-heading {
    color: white;
    font-size: 1.3rem;
    font-family: "Open Sans";
    font-weight: 300;
    text-decoration: underline;
    margin-bottom: 8px;
 }

 .discoverAndCompare-text-sub-heading {
    font-size: 1.1rem;
    font-family: "cormorant-garamond";
    font-style: italic;
    font-weight: 600;
    word-wrap: normal;
    color: white;
    width: auto;
    text-align: center;
 }

 @media (max-width: 768px) {
    .discoverAndCompare-text-heading {
        font-size: 0.7rem;
        margin-bottom: 3px;
    }
    .discoverAndCompare-text-sub-heading {
        font-size: 0.6rem;
    }
    .discoverAndCompare-sub-sub-sub-container {
        width: 5rem;
        height: 5rem;
        padding: 1.7rem;
    } 
    .discoverAndCompare-sub-sub-container{
        margin-left: 8px;
        margin-right: 8px;
    }
}


