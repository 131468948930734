.header-container {
    background-color: #fff;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px 10px 20px;
  }

.header-logo {
    max-width : 55px; 
    height: auto;
    cursor: pointer;
  }

.header-company-name {
    text-align: 'center';
    color: black;
    font-family:'cormorant-garamond';
    font-size: 3rem;
    font-weight: 700; 
    font-style: italic;
}

.header-menu-container {
    position: relative;
}

.header-menu-icon{
    cursor: pointer;
    color: black;
    font-size: 25px;
}

@media (max-width: 768px) {
    .header-company-name {
        font-size: 1.8rem;
    }

    .header-logo {
        max-width : 40px; 
    }
}