
.tdp-perspective-headings{
    margin-left: 20px;
    margin-top: 30px;
    color: #010105;
    font-size: 3rem;
    font-family: "cormorant-garamond";
    font-style: italic;
    font-weight: 600;
    word-wrap: break-word;
    margin-bottom: 30px;
  }

.tdp-perspective-line {
  width: 100%;
  background-color: #000;
  height: 1px;
}

.tdp-perspective-container{
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    padding: 20px 0px;
    /* max-height: 600px; */
}

.tdp-perspective-sub-container {
    width: 29.33%;
    /* align-items: center; */
    margin-left: 2%;
    margin-right: 2%;
    display: flex;
    flex-direction: column;
}

.tdp-perspective-image{ 
    max-width: 100%;  
    height: auto;
    /* align-self: center; */
}

.tdp-perspective-type {
  color: #010105;
  font-size: 1.2rem;
  font-family: "Open Sans";
  font-weight: 300;
  word-wrap: break-word;
  margin-top: 12px;
}

.tdp-perspective-title {
  color: #010105;
  font-size: 1.8rem;
  font-family: "cormorant-garamond";
  font-weight: 700;
  word-wrap: break-word;
  padding: 16px 40px 0px 0px;
  --max-lines: 2;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--max-lines);
}

  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .tdp-perspective-headings{
      font-size: 1.5rem;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .tdp-perspective-type {
      font-size: 0.7rem;
      margin-top: 6px;
    }

    .tdp-perspective-title {
      font-size: 0.8rem;
      padding: 4px 12px 0px 0px;
    }
  }