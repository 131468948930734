.discover-brands-container {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;
    padding-bottom: 20px;
    margin-top: 20px;
    max-height: 600px;
}

.discover-brands-image1 {
    width: 32%;   
    max-width: 100%;  
    height: auto;
}

.discover-brands-image2 {
    width: 36%;   
    max-width: 100%;  
    height: auto;
    padding-left: 10px;
    padding-right: 10px;
}

.discover-brands-image3 {
    width: 32%;   
    max-width: 100%; 
    height: auto;
}

.discover-brands-text {
    position: absolute;
    background-color: #fff;
    padding: 16px 3.5rem;
    bottom: 0;
    font-size: 2.5rem;
    font-family: "cormorant-garamond";
    font-style: italic;
    font-weight: 600;
}

@media (max-width: 768px) {
    .discover-brands-text {
        font-size: 1.3rem; /* Adjust font size for smaller screens */
        padding: 12px 2rem; /* Adjust padding for smaller screens */
    }

    .discover-brands-image2 {
        padding-left: 5px;
        padding-right: 5px;
    }
}