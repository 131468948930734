
.tdp-recommends-headings{
  margin-left: 20px;
  margin-top: 30px;
  color: #010105;
  font-size: 3rem;
  font-family: "cormorant-garamond";
  font-style: italic;
  font-weight: 600;
  word-wrap: break-word;
}

.tdp-recommends-container {
    display: flex;
    flex-direction: row;
    align-items: center; /* Center items vertically */
    margin: 0px 0px 20px 0px;
    border: 1px solid black;
    align-items: stretch;
    border-width: 1px 0px 1px 0px;
    max-height: 550px;
  }
  
  .tdp-recommends-image {
    max-width: 45%; /* Make the image responsive within its container */
    max-height: auto; /* Maintain aspect ratio */
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .tdp-recommends-text-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    padding: 20px 20px 20px 30px;
    border: solid black;
    border-width: 0px 0px 0px 1px;
    justify-content: space-between;
  }

  .tdp-recommends-featured-text {
    color: #010105;
    font-size: 1.3rem;
    font-family: "Open Sans";
    font-weight: 300;
    word-wrap: break-word;
    padding-bottom: 3rem;
  }

  .tdp-recommends-brand-name {
    color: #010105;
    font-size: 2.2rem;
    font-family: "cormorant-garamond";
    font-weight: 700;
    word-wrap: break-word;
  }

.tdp-recommends-product-name  {
    color: #010105;
    font-size: 2.2rem;
    font-family: "cormorant-garamond";
    font-style: italic;
    font-weight: 700;
    word-wrap: break-word;
  }

.tdp-featured-product-quantity {
  color: #010105;
  font-size: 1.3rem;
  font-family: "cormorant-garamond";
  font-weight: 600;
  word-wrap: break-word;
  padding-bottom: 4rem;
  padding-top: 1.5rem;
}

.tdp-recommends-product-description {
  padding-bottom: 2rem;
  color: #010105;
  font-size: 1.2rem;
  font-family: "Open Sans";
  font-weight: 300;
  word-wrap: break-word;
}

.tdp-recommends-button {
  background-color: black;
  color: #FFF;
  padding: 0.7rem 5rem;
  border-radius: 8px;
  font-size: 1.3rem;
  font-family: "Open Sans";
  font-weight: 300;
  word-wrap: break-word;
  margin-top: 2rem;
  cursor: pointer;
}


  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .tdp-recommends-featured-text {
        font-size: 0.5rem;
        padding-bottom: 1rem;
    }

    .tdp-recommends-brand-name {
      font-size: 0.9rem;
    }

    .tdp-recommends-product-name  {
      font-size: 0.9rem;
    }

    .tdp-featured-product-quantity {
      font-size: 0.7rem;
      padding-bottom: 1.5rem;
      padding-top: 0.8rem;
    }

    .tdp-recommends-product-description {
      font-size: 0.6rem;
      padding-bottom: 1.5rem;
    }

    .tdp-recommends-button {
      padding: 0.4rem 1.5rem;
      font-size: 0.6rem;
      margin-top: 0.4rem;
    }

    .tdp-recommends-headings{
      margin-top: 20px;
      font-size: 1.5rem;
    }
  }