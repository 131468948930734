.mega-menu-container {
    display: flex;
    padding: 0.8rem 0rem;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.mega-menu-category{
    padding: 0 3rem;
    color: #010105;
    font-size: 1.2rem;
    font-family: "cormorant-garamond";
    font-weight: 400;
    word-wrap: break-word;
    cursor: pointer;
}

@media (max-width: 768px) {
    .mega-menu-container{
        padding: 0.4rem 0rem;
    }

    .mega-menu-category{
        padding: 0 0.5rem;
        font-size: 0.8rem;
    }
}