.tdp-signupbanner-container {
    display: flex;
    background-color: #9E5E33;
    width: 100%;
    max-height: 620px;
}

.tdp-signupbanner-sub-container-1 {
    width: 25%;
    height: auto;
}

.tdp-signupbanner-sub-container-2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tdp-signupbanner-sub-container-3 {
    width: 32%;
    height: auto;
}

.tdp-signupbanner-title-1 {
    color: white;
    font-size: 4rem;
    font-family: "cormorant-garamond";
    font-style: italic;
    font-weight: 500;
    word-wrap: break-word;
    text-align: center;
    padding: 0 4rem;
    margin-bottom: 5rem;
}

.tdp-signupbanner-title-2 {
    color: white;
    font-size: 1.3rem;
    font-family: "Open Sans";
    font-weight: 300;
    padding: 0 6rem;
    word-wrap: break-word;
    text-align: center;
    margin-bottom: 4rem;
}

.tdp-signupbanner-button {
    background-color: #000;
    color: white;
    font-size: 1rem;
    font-family: "Open Sans";
    font-weight: 300;
    word-wrap: break-word;
    padding: 1rem 6rem;
    border-radius: 12px;
}

@media (max-width: 768px) {
    .tdp-signupbanner-title-1 {
        font-size: 1rem;
        padding: 0 2.5rem;
        margin-bottom: 1rem;
    }

    .tdp-signupbanner-title-2 {
        font-size: 0.6rem;
        padding: 0 1.3rem;
        margin-bottom: 0.8rem;
    }

    .tdp-signupbanner-button {
        font-size: 0.5rem;
        padding: 0.4rem 2.4rem;
        border-radius: 4px;
    }
}